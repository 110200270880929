import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { appURLs } from "@/app/app-urls.mjs";
import { getWikiLink } from "@/game-palworld/components/wiki.style.jsx";
import type { Pal } from "@/game-palworld/models/model-wiki.mjs";
import DataTable from "@/shared/DataTable.jsx";

export const DroppedByTable = ({ pals }: { pals: Pal[] }) => {
  const { t } = useTranslation();

  return (
    <Card
      padding="0"
      title={
        <span>
          {t("palworld:droppedBy", "Dropped By")}{" "}
          <span className="shade2">({pals.length})</span>
        </span>
      }
    >
      <DataTable
        borderless
        sortable={false}
        cols={[
          {
            display: t("palworld:pal", "Pal"),
            align: "left",
          },
        ]}
        rows={pals.map(({ id, label, imageUri }, i) => {
          return [
            {
              value: i,
              display: (
                <span className="flex align-center gap-1 w-full">
                  <img
                    width={32}
                    height={32}
                    src={`${appURLs.CDN_PLAIN}/${imageUri}`}
                    alt={t(...label)}
                  />
                  <span className="item-name">{t(...label)}</span>
                </span>
              ),
              link: getWikiLink(`pal:${id}`),
            },
          ];
        })}
      />
    </Card>
  );
};
