import type { Item } from "@/game-palworld/models/model-wiki.mjs";

export const categoryValidator = {
  isAccessory: (
    i: Item,
  ): i is Omit<Item, "effectLabel"> & { effectLabel: [string] } =>
    i.category === "accessories",
  isArmor: (i: Item): i is Item & { defense: number; plusHp: number } =>
    i.category === "armor" && i.subCategory !== "shield",
  isShield: (i: Item): i is Item & { shield: number } =>
    i.category === "armor" && i.subCategory === "shield",
  isSkillFruit: (i: Item): i is Item & { skill: Item["skill"] } =>
    i.category === "consumables" && i.subCategory === "skillFruit",
};
