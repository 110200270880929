import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Card from "clutch/src/Card/Card.jsx";
import type { TFunction } from "i18next";
import { t as gt } from "i18next";

import { IS_APP } from "@/__main__/constants.mjs";
import { DroppedByTable } from "@/game-palworld/components/DroppedByTable.jsx";
import { ItemList } from "@/game-palworld/components/ItemList.jsx";
import { ItemSidebarCard } from "@/game-palworld/components/ItemSidebar.jsx";
import { GrantedSkill } from "@/game-palworld/components/PalInfo.jsx";
import { RecipeTable } from "@/game-palworld/components/RecipeTable.jsx";
import { useItemInfo } from "@/game-palworld/components/use-item-info.mjs";
import {
  WikiCard,
  WikiLayout,
} from "@/game-palworld/components/wiki.style.jsx";
import type { ItemCategory } from "@/game-palworld/constants/items.mjs";
import { ITEM_CATEGORIES } from "@/game-palworld/constants/items.mjs";
import type { Item, Technology } from "@/game-palworld/models/model-wiki.mjs";
import { categoryValidator } from "@/game-palworld/utils/item-category-validator.mjs";
import {
  getWikiData,
  useWikiData,
} from "@/game-palworld/utils/use-wiki-data.mjs";
import ItemCommon from "@/game-palworld/wiki/item-common.mdx";
import SphereCommon from "@/game-palworld/wiki/sphere-common.mdx";
import { useRoute } from "@/util/router-hooks.mjs";

type ViewProps = { t: TFunction; item: Item; technology: Technology };
const viewOverride: PartialRecord<
  ItemCategory,
  (p: ViewProps) => React.JSX.Element
> = {
  accessories: ({ t, item }) =>
    categoryValidator.isAccessory(item) && (
      <WikiCard>
        <ItemCommon t={t} noCard i18nKey="palworld:items.common" item={item} />
        <h3>{t("common:effect", "Effect")}</h3>
        <p>{t(...item.effectLabel)}</p>
      </WikiCard>
    ),
  consumables: ({ t, item, technology }) => (
    <>
      <ItemCommon
        t={t}
        item={item}
        technology={technology}
        i18nKey="palworld:items.common"
      />
      {categoryValidator.isSkillFruit(item) && (
        <WikiCard>
          <h3>{t("palworld:grantedSkill", "Granted Skill")}</h3>
          <GrantedSkill {...item.skill} />
        </WikiCard>
      )}
    </>
  ),
  spheres: ({ t, item, technology }) => (
    <SphereCommon
      t={t}
      i18nKey="palworld:spheres.common"
      sphere={item}
      technology={technology}
    />
  ),
};

const ItemsLanding = ({
  items,
  category,
}: {
  items: Record<string, Item>;
  category: ItemCategory;
}) => {
  const itemList = useMemo(
    () =>
      items
        ? Object.values(items).filter((b) => b.category === category)
        : null,
    [items, category],
  );

  return (
    <WikiLayout title={ITEM_CATEGORIES[category].label}>
      <div className="main">
        <ItemList items={itemList} />
      </div>
    </WikiLayout>
  );
};

const Items = () => {
  const { t } = useTranslation();
  const {
    parameters: [category, itemId],
  } = useRoute();
  const { items, itemTech } = useWikiData();

  const item = items?.[itemId];
  const itemError = !item || item.category !== category;
  const [droppedBy, recipies, uses] = useItemInfo(!itemError ? item : null);

  if (!itemId || (items && !item))
    return <ItemsLanding items={items} category={category as ItemCategory} />;

  if (!item || !itemTech)
    return (
      <WikiLayout title={["palworld:building", "Building"]}>
        <div className="sidebar">
          <Card loading style={{ height: "460px" }} />
        </div>
        <div className="main">
          <Card loading style={{ height: "184px" }} />
        </div>
      </WikiLayout>
    );

  const Component = viewOverride[category] ?? ItemCommon;
  const technology = itemTech[item.techRequirementId];
  return (
    <WikiLayout title={item.label}>
      <div className="sidebar">
        <ItemSidebarCard t={t} item={item} technology={technology} />
      </div>
      <div className="main">
        <Component
          t={t}
          item={item}
          technology={technology}
          i18nKey="palworld:items.common"
        />
        {!!droppedBy.length && <DroppedByTable pals={droppedBy} />}
        {!!recipies.length && <RecipeTable recipes={recipies} />}
        {!!uses.length && <RecipeTable recipes={uses} />}
      </div>
    </WikiLayout>
  );
};

export function meta([category, itemId]) {
  const { items } = getWikiData();
  const item = items?.[itemId];

  const itemName = item ? gt(...item.label) : gt("palworld:item", "Item");
  if (itemId && (!items || item))
    return {
      title: [
        "palworld:itemSeo.title",
        "{{itemName}} in Palworld: Complete Profile, Breeding & Strategy Guide | Blitz",
        { itemName },
      ],
      description: [
        "palworld:itemLanding.desc",
        "Unlock the secrets of Palworld with our ultimate guide! Explore comprehensive insights on Palworld items.",
      ],
      subtitle: !IS_APP,
    };

  return {
    title: [
      "palworld:itemLanding.title",
      "Palworld {{category}}",
      { category: gt(...ITEM_CATEGORIES[category].label) },
    ],
    description: [
      "palworld:buildingLanding.desc",
      "Unlock the secrets of Palworld with our ultimate guide! Explore comprehensive insights on Palworld items.",
    ],
    subtitle: !IS_APP,
  };
}

export default Items;
