import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import {ITEM_CATEGORIES} from "@/game-palworld/constants/items.mjs";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    h2: "h2",
    p: "p",
    strong: "strong",
    ..._provideComponents(),
    ...props.components
  }, {ShowIf, WikiLink} = _components;
  if (!ShowIf) _missingMdxReference("ShowIf", true);
  if (!WikiLink) _missingMdxReference("WikiLink", true);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h2, {
      id: "overview",
      children: _jsx(_components.a, {
        href: "#overview",
        children: "Overview"
      })
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: props.t(...props.item.label)
      }), " is a ", _jsx(WikiLink, {
        href: `/palworld/database/${props.item.category}`,
        children: props.t(...ITEM_CATEGORIES[props.item.category].label)
      }), " in ", _jsx(_components.a, {
        href: "/palworld/database",
        children: "Palworld"
      }), ". ", props.t(...props.item.description)]
    }), "\n", _jsx(ShowIf, {
      cond: !!props.technology,
      children: _jsxs(_components.p, {
        children: ["It's a level ", props.technology?.levelRequirement, " ", _jsx(_components.a, {
          href: "/palworld/database/technology",
          children: "technology"
        }), " item and requires ", props.technology?.pointCost, " point(s) to unlock."]
      })
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
