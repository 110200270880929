import React from "react";
import type { TFunction } from "i18next";

import { appURLs } from "@/app/app-urls.mjs";
import {
  WikiLink,
  WikiSidebar,
} from "@/game-palworld/components/wiki.style.jsx";
import type { ItemCategory } from "@/game-palworld/constants/items.mjs";
import {
  ITEM_CATEGORIES,
  ITEM_SUB_CATEGORIES,
} from "@/game-palworld/constants/items.mjs";
import { RARITY_LIST } from "@/game-palworld/constants/rarity.mjs";
import type { Item, Technology } from "@/game-palworld/models/model-wiki.mjs";
import { categoryValidator } from "@/game-palworld/utils/item-category-validator.mjs";

const categoryExclusionMap: PartialRecord<ItemCategory, boolean> = {
  accessories: true,
  ammo: true,
  blueprints: true,
  spheres: true,
};

type SidebarProps = { t: TFunction; item: Item; technology?: Technology };
const CommonInfo = ({ t, item }: SidebarProps) => (
  <>
    <dt>{t("palworld:item.type", "Type")}</dt>
    <dd>
      <a href={`/palworld/database/${item.category}`}>
        {t(...ITEM_CATEGORIES[item.category].label)}
      </a>
    </dd>
    {!categoryExclusionMap[item.category] && (
      <>
        <dt>{t("palworld:item.category", "Category")}</dt>
        <dd>
          <a
            href={`/palworld/database/${item.category}?category=${item.subCategory}`}
          >
            {t(...ITEM_SUB_CATEGORIES[item.subCategory].label)}
          </a>
        </dd>
      </>
    )}
    <dt>{t("palworld:item.rarity", "Rarity")}</dt>
    <dd>{t(...RARITY_LIST[item.rarity].label)}</dd>
    <dt>{t("palworld:item.sellValue", "Sell value")}</dt>
    <dd>{item.sellPrice.toLocaleString()}</dd>
    <dt>{t("palworld:item.weight", "Weight")}</dt>
    <dd>{item.weight.toString()}</dd>
  </>
);

const CommonTech = ({ t, technology }: SidebarProps) =>
  technology && (
    <>
      <dt>{t("palworld:unlockedBy", "Unlocked By")}</dt>
      <dd>
        <WikiLink href={`technology:${technology.id}`}>
          {technology.id}
        </WikiLink>
      </dd>
      <dt>{t("palworld:item.levelRequirement", "Level Req.")}</dt>
      <dd>{technology.levelRequirement.toLocaleString()}</dd>
      <dt>
        {t(
          ...(technology.isAncientTech
            ? ["palworld:item.ancientPointCost", "Ancient Point Cost"]
            : ["palworld:item.pointCost", "Tech Point Cost"]),
        )}
      </dt>
      <dd>{technology.pointCost.toLocaleString()}</dd>
    </>
  );

const CommonSidebar = (p: SidebarProps) => (
  <>
    <CommonInfo {...p} />
    <CommonTech {...p} />
  </>
);

const sidebarOverrides: PartialRecord<
  ItemCategory,
  (p: SidebarProps) => React.JSX.Element
> = {
  armor: ({ t, item, ...p }) => (
    <>
      <CommonInfo t={t} item={item} {...p} />
      {categoryValidator.isArmor(item) && (
        <>
          <dt>{t("palworld:item.defense", "Defense")}</dt>
          <dd>{item.defense.toLocaleString()}</dd>
          <dt>{t("palworld:item.extraHp", "Extra Hp")}</dt>
          <dd>{item.plusHp.toLocaleString()}</dd>
        </>
      )}
      {categoryValidator.isShield(item) && (
        <>
          <dt>{t("palworld:item.shield", "Shield")}</dt>
          <dd>{item.shield.toLocaleString()}</dd>
        </>
      )}
      <CommonTech t={t} item={item} {...p} />
    </>
  ),
  foods: ({ t, item, ...p }) => (
    <>
      <CommonInfo t={t} item={item} {...p} />
      <dt>{t("palworld:item.satiety", "Satiety")}</dt>
      <dd>{item.satiety.toString()}</dd>
      <dt>{t("palworld:item.sanity", "Sanity")}</dt>
      <dd>{item.sanity.toString()}</dd>
      <CommonTech t={t} item={item} {...p} />
    </>
  ),
  spheres: ({ t, item, ...p }) => (
    <>
      <CommonInfo t={t} item={item} {...p} />
      <dt>{t("palworld:item.capturePower", "Capture Power")}</dt>
      <dd>{item.capturePower.toString()}</dd>
      <CommonTech t={t} item={item} {...p} />
    </>
  ),
  weapons: ({ t, item, ...p }) => (
    <>
      <CommonInfo t={t} item={item} {...p} />
      <dt>{t("palworld:item.durability", "Durability")}</dt>
      <dd>{item.durability.toString()}</dd>
      <dt>{t("palworld:item.attackDmg", "Attack Damage")}</dt>
      <dd>{item.attackDmg.toLocaleString()}</dd>
      {item.magazineSize && (
        <>
          <dt>{t("palworld:item.magSize", "Magazine Size")}</dt>
          <dd>{item.magazineSize.toLocaleString()}</dd>
        </>
      )}
      <CommonTech t={t} item={item} {...p} />
    </>
  ),
};

export const ItemSidebarCard = ({ t, item, technology }: SidebarProps) => {
  const Component = sidebarOverrides[item.category] ?? CommonSidebar;
  return (
    <WikiSidebar
      imgSrc={`${appURLs.CDN_PLAIN}/${item.imageUri}`}
      itemName={item.label}
    >
      <WikiSidebar.Info>
        <Component t={t} item={item} technology={technology} />
      </WikiSidebar.Info>
    </WikiSidebar>
  );
};
