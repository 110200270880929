import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "clutch/src/Select/Select.js";
import { TextInput } from "clutch/src/TextInput/TextInput.js";
import type { TFunction } from "i18next";

import { appURLs } from "@/app/app-urls.mjs";
import { getWikiLink } from "@/game-palworld/components/wiki.style.jsx";
import {
  getFilterByLabel,
  useWikiListFilter,
  WikiColumnName,
  WikiList,
} from "@/game-palworld/components/WikiList.jsx";
import type { ItemCategory } from "@/game-palworld/constants/items.mjs";
import { ITEM_SUB_CATEGORIES } from "@/game-palworld/constants/items.mjs";
import { RARITIES, RARITY_LIST } from "@/game-palworld/constants/rarity.mjs";
import type { Item, Technology } from "@/game-palworld/models/model-wiki.mjs";
import { categoryValidator } from "@/game-palworld/utils/item-category-validator.mjs";
import { getWikiData } from "@/game-palworld/utils/use-wiki-data.mjs";
import SearchIcon from "@/inline-assets/search-icon.svg";
import type { Cell } from "@/shared/DataTable.jsx";
import DataTable from "@/shared/DataTable.jsx";
import SortOrder from "@/shared/SortOrder";
import { useQuery } from "@/util/router-hooks.mjs";

const subCategoryOptions = Object.entries(ITEM_SUB_CATEGORIES).reduce(
  (acc, [k, v]) => {
    acc.push({ value: k, text: v.label, image: "" });
    return acc;
  },
  [
    {
      value: "",
      text: ["palworld:category.all", "All Categories"] as Translation,
      image: "",
    },
  ],
);

const rarityOptions = Object.entries(RARITIES).reduce(
  (acc, [k, v]) => {
    acc.push({ value: k, text: v.label, image: "" });
    return acc;
  },
  [
    {
      value: "",
      text: ["palworld:rarity.all", "All Rarities"] as Translation,
      image: "",
    },
  ],
);

type ItemSortOption =
  | {
      text: Translation;
      value: string;
      sortBy: keyof Item;
      techSortBy?: never;
      fallback?: never;
    }
  | {
      text: Translation;
      value: string;
      sortBy?: never;
      techSortBy: keyof Technology;
      /**
       * If there is no tech for this item
       */
      fallback: string | number | null;
    }
  | {
      text: Translation;
      value: string;
      sortBy: string;
      techSortBy?: never;
      fallback: string | number | null;
    };

const getFilterByCategory =
  (categoryFilter: string) =>
  <T extends { subCategory: string }>(_t: TFunction, i: T) =>
    i.subCategory === categoryFilter;

const getFilterByRarity =
  (rarityFilter: string) =>
  <T extends { rarity: number }>(_t: TFunction, i: T) =>
    RARITY_LIST[i.rarity].key === rarityFilter;

const getItemSortValue = (
  sortOpt: ItemSortOption,
  a: Item,
  b: Item,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): [any, any] => {
  let aV;
  let bV;
  if (sortOpt.techSortBy) {
    aV = getWikiData().itemTech?.[a.techRequirementId]?.[sortOpt.techSortBy];
    bV = getWikiData().itemTech?.[b.techRequirementId]?.[sortOpt.techSortBy];
  } else {
    aV = a[sortOpt.sortBy];
    bV = b[sortOpt.sortBy];
  }

  return [aV ?? sortOpt.fallback, bV ?? sortOpt.fallback];
};

type ColumnType = keyof typeof columnTypes;
const columnTypes = {
  name: {
    display: ["palworld:itemName", "Item Name"],
    align: "left",
    primary: true,
  },
  category: {
    display: ["palworld:category", "Category"],
  },
  levelReq: {
    display: ["palworld:levelReq", "Level Req."],
    isStat: true,
    align: "right",
  },
  rarity: {
    display: ["palworld:rarity", "Rarity"],
  },
  weight: {
    display: ["palworld:weight", "Weight"],
    isStat: true,
    align: "right",
  },
  durability: {
    display: ["palworld:durability", "Durability"],
    align: "right",
    isStat: true,
  },
  effect: {
    display: ["palworld:effect", "Effect"],
  },
  defense: {
    display: ["palworld:defense", "Defense"],
    align: "right",
    isStat: true,
  },
  shield: {
    display: ["palworld:shield", "Shield"],
    align: "right",
    isStat: true,
  },
  satiety: {
    display: ["palworld:satiety", "Satiety"],
    align: "right",
    isStat: true,
  },
  sanity: {
    display: ["palworld:sanity", "Sanity"],
    align: "right",
    isStat: true,
  },
  capturePower: {
    display: ["palworld:capturePower", "Capture Power"],
    align: "right",
    isStat: true,
  },
  damage: {
    display: ["palworld:attackDamage", "Attack Damage"],
    align: "right",
    isStat: true,
  },
  magSize: {
    display: ["palworld:magSize", "Magazine Size"],
    align: "right",
    isStat: true,
  },
} as const;

const rowMappers: Record<
  ColumnType,
  (p: { t: TFunction; item: Item }) => Cell
> = {
  name: ({ t, item }) => ({
    display: (
      <WikiColumnName
        name={t(...item.label)}
        imgSrc={`${appURLs.CDN_PLAIN}/${item.imageUri}`}
      />
    ),
    value: null,
    link: getWikiLink(`${item.category}:${item.id}`),
  }),
  rarity: ({ t, item }) => ({
    value: item.rarity,
    display: t(...RARITY_LIST[item.rarity].label),
  }),
  effect: ({ t, item }) =>
    categoryValidator.isAccessory(item) && {
      value: null,
      display: t(...item.effectLabel),
    },
  weight: ({ item }) => ({
    value: item.weight,
    display: item.weight.toLocaleString(),
  }),
  category: ({ t, item }) => ({
    value: null,
    display: t(...ITEM_SUB_CATEGORIES[item.subCategory].label),
  }),
  durability: ({ item }) => ({
    value: item.durability,
    display: item.durability.toLocaleString(),
  }),
  levelReq: ({ item }) => ({
    value:
      getWikiData().itemTech?.[item.techRequirementId]?.levelRequirement || 1,
    display: (
      getWikiData().itemTech?.[item.techRequirementId]?.levelRequirement || 1
    ).toLocaleString(),
  }),
  defense: ({ item }) =>
    item.defense
      ? { value: item.defense, display: item.defense.toLocaleString() }
      : { value: 0, display: "-" },
  shield: ({ item }) =>
    item.shield
      ? { value: item.shield, display: item.shield.toLocaleString() }
      : { value: 0, display: "-" },
  satiety: ({ item }) => ({
    value: item.satiety,
    display: item.satiety.toLocaleString(),
  }),
  sanity: ({ item }) => ({
    value: item.sanity,
    display: item.sanity.toLocaleString(),
  }),
  capturePower: ({ item }) => ({
    value: item.capturePower,
    display: item.capturePower.toLocaleString(),
  }),
  damage: ({ item }) => ({
    value: item.attackDmg,
    display: item.attackDmg.toLocaleString(),
  }),
  magSize: ({ item }) => ({
    value: item.magazineSize,
    display: item.magazineSize ? item.magazineSize.toLocaleString() : "-",
  }),
};

type SortType = keyof typeof sortTypes;
const sortTypes = {
  name: {
    value: "name",
    text: ["palworld:sort.name", "Name"],
    sortBy: "label",
  },
  category: {
    value: "category",
    text: ["palworld:sort.subCategory", "Category"],
    sortBy: "subCategory",
  },
  rarity: {
    value: "rarity",
    text: ["palworld:sort.rarity", "Rarity"],
    sortBy: "rarity",
  },
  weight: {
    value: "weight",
    text: ["palworld:sort.weight", "Weight"],
    sortBy: "weight",
  },
  levelReq: {
    value: "levelReq",
    text: ["palworld:sort.levelReq", "Level Requirement"],
    techSortBy: "levelRequirement",
    fallback: 1,
  },
  durability: {
    value: "durability",
    text: ["palworld:sort.durability", "Durability"],
    sortBy: "durability",
  },
  defense: {
    value: "defense",
    text: ["palworld:sort.defense", "Defense"],
    sortBy: "defense",
    fallback: 0,
  },
  shield: {
    value: "shield",
    text: ["palworld:sort.shield", "Shield"],
    sortBy: "shield",
    fallback: 0,
  },
  satiety: {
    value: "satiety",
    text: ["palworld:sort.satiety", "Satiety"],
    sortBy: "satiety",
  },
  sanity: {
    value: "sanity",
    text: ["palworld:sort.sanity", "Sanity"],
    sortBy: "sanity",
  },
  capturePower: {
    text: ["palworld.sort.capturePower", "Capture Power"],
    value: "capture-power",
    sortBy: "capturePower",
  },
  damage: {
    value: "damage",
    text: ["palworld:sort.attackDamage", "Attack Damage"],
    sortBy: "attackDmg",
  },
  magSize: {
    value: "mag-size",
    text: ["palworld:sort.magSize", "Magazine Size"],
    sortBy: "magazineSize",
  },
} as const;

const categoryCols: Record<ItemCategory, ColumnType[]> = {
  accessories: ["name", "rarity", "effect", "weight"],
  ammo: ["name", "rarity", "weight", "levelReq"],
  armor: [
    "name",
    "category",
    "rarity",
    "weight",
    "defense",
    "shield",
    "durability",
    "levelReq",
  ],
  blueprints: ["name", "rarity"],
  consumables: ["name", "category", "rarity", "weight", "levelReq"],
  foods: [
    "name",
    "category",
    "rarity",
    "weight",
    "satiety",
    "sanity",
    "levelReq",
  ],
  gliders: ["name", "rarity", "weight", "levelReq"],
  keyItems: ["name", "category", "rarity", "levelReq"],
  materials: ["name", "category", "rarity", "weight", "levelReq"],
  spheres: ["name", "rarity", "capturePower", "levelReq"],
  weapons: [
    "name",
    "category",
    "rarity",
    "weight",
    "damage",
    "magSize",
    "durability",
    "levelReq",
  ],
};

const categorySort: Record<ItemCategory, SortType[]> = {
  accessories: ["name", "rarity"],
  ammo: ["name", "levelReq"],
  armor: ["name", "levelReq", "durability", "defense", "shield"],
  blueprints: ["name"],
  consumables: ["name", "rarity", "weight"],
  foods: ["name", "satiety", "sanity", "weight", "rarity", "levelReq"],
  gliders: ["levelReq", "name"],
  keyItems: ["name", "levelReq"],
  materials: ["name", "weight", "rarity", "levelReq"],
  spheres: ["name", "capturePower", "rarity", "weight", "levelReq"],
  weapons: ["name", "levelReq", "durability", "damage", "magSize"],
};

const createGetRows =
  (category: ItemCategory) => (items: Item[], t: TFunction) =>
    items.map((item) =>
      categoryCols[category].map((c) => rowMappers[c]({ t, item })),
    );

export function ItemList({ items }: { items: Item[] }) {
  const { t } = useTranslation();

  const [nameFilter, setNameFilter] = useQuery<string>("name", "");
  const [categoryFilter, setCategoryFilter] = useQuery<string>("category", "");
  const [rarityFilter, setRarityFilter] = useQuery<string>("rarity", "");

  const filters = useMemo(() => {
    const filters = [];
    if (nameFilter) filters.push(getFilterByLabel(nameFilter));
    if (categoryFilter) filters.push(getFilterByCategory(categoryFilter));
    if (rarityFilter) filters.push(getFilterByRarity(rarityFilter));

    return filters.length ? filters : null;
  }, [categoryFilter, nameFilter, rarityFilter]);

  const category = items?.[0].category;
  const [cols, getRows] = useMemo(() => {
    if (!category) return [[], () => null];
    const cols = categoryCols[category].map((c) => columnTypes[c]);
    return [cols, createGetRows(category)];
  }, [category]);

  const sortOptions = categorySort[category]?.map((c) => sortTypes[c]);
  const {
    allCols,
    rows,
    sortOption: [sortMode, setSortMode],
    sortToggle: [sortOrder, setSortOrder],
  } = useWikiListFilter({
    t,
    cols,
    getSortValue: getItemSortValue,
    filters,
    getRows,
    items,
    sortOptions,
  });

  const filteredRarityOptions = useMemo(() => {
    if (!items) return rarityOptions;
    return rarityOptions.filter(
      (_, idx) => idx === 0 || items.some((it) => it.rarity === idx),
    );
  }, [items]);

  const filteredCategoryOptions = useMemo(() => {
    if (!items) return subCategoryOptions;
    return subCategoryOptions.filter(
      (opt) => items.find((it) => it.subCategory === opt.value) || !opt.value,
    );
  }, [items]);

  return (
    <WikiList>
      <div className="search-container">
        <TextInput
          placeholder={t("common:search", "Search")}
          onChange={(e) => setNameFilter(e.target.value)}
          defaultValue={nameFilter}
          Icon={SearchIcon}
        />
        {filteredCategoryOptions.length > 2 && (
          <Select
            options={filteredCategoryOptions}
            selected={categoryFilter || ""}
            onChange={(v) => setCategoryFilter(v || undefined)}
          />
        )}
        {filteredRarityOptions.length > 2 && (
          <Select
            options={filteredRarityOptions}
            selected={rarityFilter || ""}
            onChange={(v) => setRarityFilter(v || undefined)}
          />
        )}
        <div className="sort-container">
          <Select
            disabled={!sortOptions}
            options={sortOptions ?? [sortTypes.name]}
            selected={sortMode || "name"}
            onChange={(v) => setSortMode(v || undefined)}
          />
          <SortOrder sortOrder={sortOrder} setSortOrder={setSortOrder} />
        </div>
      </div>
      <DataTable
        className="wiki-table"
        sortable={false}
        cols={allCols}
        rows={rows}
      />
    </WikiList>
  );
}
