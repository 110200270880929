import { useMemo } from "react";

import type { Item, Pal, Recipe } from "@/game-palworld/models/model-wiki.mjs";
import { useWikiData } from "@/game-palworld/utils/use-wiki-data.mjs";

export function useItemInfo(item: Item) {
  const { pals, items } = useWikiData();

  const droppedBy = useMemo(() => {
    if (!item?.internalId || !pals) return [];
    const droppedBy: Pal[] = [];
    for (const pal of Object.values(pals)) {
      if (pal.drops.some(({ internalId }) => internalId === item.internalId))
        droppedBy.push(pal);
    }
    return droppedBy;
  }, [item?.internalId]);

  const uses = useMemo(() => {
    if (!item?.internalId) return [];
    const recipes: Recipe[] = [];
    for (const itemData of Object.values(items)) {
      for (const r of itemData.recipes) {
        if (
          r.materials.some(({ internalId }) => internalId === item.internalId)
        )
          recipes.push(r);
      }
    }
    return recipes;
  }, [item?.internalId]);

  return [droppedBy, item?.recipes ?? [], uses] as const;
}
